
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { createVNode, defineComponent, ref, reactive, UnwrapRef, toRefs, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { ExclamationCircleOutlined, PlusOutlined, HomeOutlined, ArrowLeftOutlined, ArrowRightOutlined, ReloadOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'

import moment from 'moment'

import validate from '../../../services/validator'
import router from '../../../router'
import profileService from '../../../services/profile'
import courseService from '../../../services/course'
import certificateService from '../../../services/certficate'
import subscriptionService from '../../../services/subscription'
import commonServices from '../../../services/common'

import ICandidate from '../../../interface/profile'
import ICourse from '../../../interface/course'

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    PlusOutlined,
    ReloadOutlined,
    DownloadOutlined,
    EyeOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const activeKey = ref('1')
    const route = useRoute()
    const candidateId = ref<string>(route.params.id as string)
    const candidate = ref<ICandidate>()
    const candidateFormRef = ref()
    const candidateRules = validate.clientValidation
    const unassignedCourses = ref<Array<ICourse>>([])
    const assignedCourses = ref<Array<ICourse>>([])
    const availableCourse = ref([])
    const availableGroupedCourse = ref([])
    const groupCourse = ref([])
    const selectedCourseToDisplay = ref([])
    const visible = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const certificates = ref()
    const completedCourses = ref()
    const availableCredit = ref<number>(0)
    const subscription = ref()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const state = reactive({
      indeterminate: false,
      selectAllCourse: false,
      selectedCourse: [],
      selectedGroup: []
    })
    const candidateForm: UnwrapRef<ICandidate> = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: '',
      profile_type: '',
      certificate_access: false,
      created_by: '',
      is_active: true,
      send_login: false,
      status: 'active'
    })
    const updateCandidate = async () => {
      candidateFormRef.value.validate().then(async () => {
        try {
          await profileService.updateProfile(candidateId.value, candidateForm)
          await notify('Success', 'Profile Updated', 'success')
          router.push(`/client/candidates${loginAs.value}`)
        } catch (error) {
          await notify('Error', error.data, 'error')
        }
      }).catch((error: ValidateErrorEntity<ICandidate>) => {
        console.log('error', error)
      })
    }
    const getCandidateDetails = async () => {
      try {
        const responce = await profileService.getProfileDetails(candidateId.value)
        candidate.value = responce.data
        Object.assign(candidateForm, responce.data)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getCertificates = async () => {
      try {
        const responce = await certificateService.getCertficates(candidateId.value)
        certificates.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getCompletedCourses = async () => {
      try {
        const responce = await courseService.getCompletedCourses(candidateId.value)
        completedCourses.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getUnassignedCourses = async () => {
      try {
        availableCourse.value = []
        const clientId = commonServices.getCurrentProfile()._id
        const responce = await courseService.getCandidateUnAssignedCourse(clientId, candidateId.value)
        unassignedCourses.value = responce.data
        for (const course of unassignedCourses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getAssignedCourse = async () => {
      try {
        const responce = await courseService.getAssignedCourse(candidateId.value)
        assignedCourses.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const closeCourseListModal = async () => {
      visible.value = false
    }
    const unAssignCourse = async (id, index) => {
      try {
        await courseService.unAssignCourse(id)
        assignedCourses.value.splice(index, 1)
        getUnassignedCourses()
        notify('Success', 'Course Unassigned Successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const deleteCertificate = async (data, index) => {
      try {
        await certificateService.deleteCertficate(data._id)
        certificates.value.splice(index, 1)
        notify('Success', 'Certficate revoked', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const retakeCourse = async (data, index) => {
      try {
        await courseService.reTakeCourse(data.course._id, candidateId.value)
        completedCourses.value.splice(index, 1)
        notify('Success', 'Course is now available for retake', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const showDeleteConfirm = async (id, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          unAssignCourse(id, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const confirmDeleteCertificate = async (data, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { }, 'Do you really want to delete this certificate?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          deleteCertificate(data, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const confirmRetakeCourse = async (data, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { }, 'Do you really want to retake this course?. This process cannot be undo. All datas related to this course for the candidate will wipe off.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          retakeCourse(data, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const assignCourse = async () => {
      try {
        const lSubscription = commonServices.getCurrentSubscription()
        const enroll = []
        loading.value = true
        for (const courseId of state.selectedCourse) {
          let expiryDate = ''
          const course = unassignedCourses.value.filter(course => course._id === courseId)
          if (course.length > 0) {
            if (course[0].validity > 0) {
              expiryDate = moment().add(course[0].validity, 'months').format()
            }
          }
          enroll.push({ enrolled_date: new Date(), profile: candidateId.value, course: courseId, expiry_date: expiryDate, subscription: lSubscription._id })
        }
        for (const groupId of state.selectedGroup) {
          const group = groupCourse.value.filter(group => group._id === groupId)
          console.log('group', group)
          if (group.length > 0) {
            for (const course of group[0].courses) {
              let expiryDate = ''
              if (course.validity > 0) {
                expiryDate = moment().add(course.validity, 'months').format()
              }
              enroll.push({ enrolled_date: new Date(), profile: candidate.value._id, course: course._id, expiry_date: expiryDate, subscription: lSubscription._id })
            }
          }
        }
        if (lSubscription.type === 'Credit') {
          const subscribedCourses = await subscriptionService.getSubscribedCourses(lSubscription._id)
          const subscribedCourseCount = subscribedCourses.data.length + enroll.length
          console.log(lSubscription.quantity, subscribedCourseCount)
          if (lSubscription.quantity < subscribedCourseCount) {
            loading.value = false
            notify('Warning', 'You dont have enough credit, please update your credit.', 'warning')
            return
          }
          lSubscription.available_credit = (lSubscription.available_credit - subscribedCourseCount)
          const responce = await subscriptionService.updateSubscription(lSubscription._id, lSubscription)
          localStorage.subscription = JSON.stringify(responce.data)
        }
        await courseService.enrollMultipleCourse(enroll)
        loading.value = false
        closeCourseListModal()
        getUnassignedCourses()
        getAssignedCourse()
        notify('Success', 'Course Assigned Successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const courseDrawer = async () => {
      visible.value = true
    }
    const onCheckAllChange = async (e: any) => {
      const aCourse = []
      const aGroup = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      for (const group of availableGroupedCourse.value) {
        aGroup.push(group.value)
      }
      Object.assign(state, {
        selectedCourse: e.target.checked ? aCourse : [],
        selectedGroup: e.target.checked ? aGroup : [],
        indeterminate: false
      })
    }
    watch(() => state.selectedCourse, (val) => {
      selectedCourseToDisplay.value = []
      for (const course of state.selectedCourse) {
        selectedCourseToDisplay.value.push(availableCourse.value.filter(c => c.value === course)[0].label)
      }
      state.indeterminate = !!val.length && val.length < availableCourse.value.length
      state.selectAllCourse = val.length === availableCourse.value.length
    })
    const next = async () => {
      let number = +activeKey.value
      number++
      activeKey.value = number.toString()
    }
    const prev = async () => {
      let number = +activeKey.value
      number--
      activeKey.value = number.toString()
    }
    const getGroupCourses = async () => {
      try {
        availableGroupedCourse.value = []
        const profile = commonServices.getCurrentProfile()
        const responce = await courseService.getGroupCourse(profile._id)
        groupCourse.value = responce.data
        for (const group of groupCourse.value) {
          availableGroupedCourse.value.push({ label: group.name, value: group._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    // const downloadCertficate = async (course, certficate, candidate, open) => {
    //   try {
    //     await certificateService.downloadCertficate(course, certficate, candidate, open)
    //   } catch (error) {
    //     console.log('error', error)
    //     await notify('Error', error.data, 'error')
    //   }
    // }
    onMounted(() => {
      getCandidateDetails()
      getUnassignedCourses()
      getGroupCourses()
      getAssignedCourse()
      getCertificates()
      getCompletedCourses()
      const lSubscription = commonServices.getCurrentSubscription()
      availableCredit.value = +lSubscription.available_credit
      subscription.value = lSubscription
    })
    return {
      candidateFormRef,
      candidateRules,
      candidateForm,
      updateCandidate,
      activeKey,
      visible,
      courseDrawer,
      closeCourseListModal,
      ...toRefs(state),
      onCheckAllChange,
      next,
      prev,
      availableCourse,
      assignedCourses,
      unassignedCourses,
      selectedCourseToDisplay,
      assignCourse,
      unAssignCourse,
      loading,
      showDeleteConfirm,
      confirmDeleteCertificate,
      // downloadCertficate,
      certificates,
      completedCourses,
      confirmRetakeCourse,
      availableGroupedCourse,
      loginAs
    }
  }
})
