import axios from 'axios'

export default {
  getProfileType (slug?) {
    return axios.get(process.env.VUE_APP_PROFILE_TYPE + (slug ? `/by/${slug}` : ''))
  },

  getClients (type) {
    return axios.get(process.env.VUE_APP_PROFILE + '/type/' + type)
  },

  createNewClient (data) {
    return axios.post(process.env.VUE_APP_PROFILE + '/client/create', data)
  },

  getCandidates (clientId, params?) {
    if (!params) params = { page: 1, pageSize: 10, search: '' }
    return axios.get(`${process.env.VUE_APP_CLIENT_USER}/get_candidates/${clientId}?pageSize=${params.pageSize}&page=${params.page}&search=${params.search}`)
  },

  getCourseCompletedCandidates (clientId, params?) {
    if (!params) params = { page: 1, pageSize: 10, search: '' }
    return axios.get(`${process.env.VUE_APP_CLIENT_USER}/get_course_completed_candidates/${clientId}?pageSize=${params.pageSize}&page=${params.page}&search=${params.search}`)
  },

  getStatistics () {
    return axios.get(process.env.VUE_APP_PROFILE + '/client/statistics')
  },

  getClientStatistics (clientId) {
    return axios.get(process.env.VUE_APP_PROFILE + '/client/dashboard/statistics/' + clientId)
  },

  getAdminStatistics () {
    return axios.get(process.env.VUE_APP_PROFILE + '/admin/dashboard/statistics')
  },

  createNewCandidate (data, id) {
    return axios.post(process.env.VUE_APP_PROFILE + '/candidate/create/' + id, data)
  },

  createNewUser (data, id) {
    return axios.post(process.env.VUE_APP_PROFILE + '/user/create/' + id, data)
  },

  getUsers (clientId) {
    return axios.get(process.env.VUE_APP_CLIENT_USER + '/client/get_users/' + clientId)
  },

  sendEmail (data) {
    return axios.post(process.env.VUE_APP_PROFILE + '/send_email', data)
  },

  getProfileDetails (id) {
    return axios.get(process.env.VUE_APP_PROFILE + '/' + id)
  },

  getClientDetails (id) {
    return axios.get(process.env.VUE_APP_CLIENT_USER + '/get_client/' + id)
  },

  getProfileDetailsFromUserId (id) {
    return axios.get(process.env.VUE_APP_PROFILE + '/details/' + id)
  },

  updateProfile (id, data) {
    return axios.put(process.env.VUE_APP_PROFILE + '/' + id, data)
  },

  activateDeactivate (id, data) {
    return axios.post(process.env.VUE_APP_PROFILE + '/activate_deactive/' + id, data)
  },

  deleteProfile (id) {
    return axios.delete(process.env.VUE_APP_PROFILE + '/' + id)
  },

  deleteUser (userName) {
    return axios.delete(process.env.VUE_APP_USER + '/' + userName)
  },

  deleteCandidate (data) {
    return axios.post(process.env.VUE_APP_PROFILE + '/delete_candidate', data)
  },

  addAddress (data) {
    return axios.post(process.env.VUE_APP_ADDRESS, data)
  },

  convertImageToBase64 (data) {
    return axios.post(process.env.VUE_APP_FILE_UPLOAD + '/convert-base64', data)
  },

  getClientMatrix (profileId, courseIds?, config?) {
    return axios.get(`${process.env.VUE_APP_PROFILE}/client/matrix/${profileId}?course_ids=${courseIds}&pageSize=${config.pageSize}&page=${config.page}&search=${config.search}`)
  },

  resendCredentials (profileIds) {
    return axios.post(process.env.VUE_APP_PROFILE + '/resend_credentials', profileIds)
  },

  importCandidates (datas, clientId) {
    return axios.post(process.env.VUE_APP_PROFILE + '/client/import-candidates/' + clientId, datas)
  }

}
