<template>
  <div class="main">
    <div class="edituser content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Candidates</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>Edit candidate</h4>
          <div class="add-outline">
            <a-button type="primary">
              <router-link :to="'/client/candidates'+loginAs"><ArrowLeftOutlined /> Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="edit-candidate">
        <a-col :xs="24" :sm="24" :md="24" :lg="7" :xl="7">
          <div class="candidateDetails">
            <h4>Candidate Details</h4>
            <a-form ref="candidateFormRef" :model="candidateForm" :rules="candidateRules" :hideRequiredMark="true" layout="vertical"
              :wrapper-col="{ span: 24 }"
              :label-col="{ span: 24 }"
            >
              <a-form-item label="First Name" required has-feedback name="first_name" >
                <a-input v-model:value="candidateForm.first_name" size="large" type="text" placeholder="Enter first Name" allow-clear autocomplete="off"/>
              </a-form-item>
              <a-form-item label="Last Name" required has-feedback name="last_name">
                <a-input v-model:value="candidateForm.last_name" size="large" type="text" placeholder="Enter last Name" allow-clear autocomplete="off"/>
              </a-form-item>
              <a-form-item label="Email" has-feedback name="email">
                <a-input type="text" size="large" v-model:value="candidateForm.email" placeholder="Enter Email" allow-clear autocomplete="off"/>
              </a-form-item>
              <a-form-item label="Phone" has-feedback name="phone">
                <a-input type="number" size="large" v-model:value="candidateForm.phone" placeholder="Enter Phone Number" allow-clear autocomplete="off"/>
              </a-form-item>
              <a-form-item label="Certificate Access" has-feedback name="certificate_access">
                <a-radio-group name="radioGroup" v-model:value="candidateForm.certificate_access">
                  <a-radio :value=true>Allowed</a-radio>
                  <a-radio :value=false>Not Allowed</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-form>
            <div class="action">
              <a-button type="primary" @click="updateCandidate">Update</a-button>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="17" :xl="17">
          <div class="tabsection">
            <a-tabs v-model:activeKey="activeKey">
              <a-tab-pane key="1" tab="Overview">
                <div class="overviewTab">
                  <div class="selectcourselist" v-if="selectedCourseToDisplay.length">
                    <h4>Selected Course</h4>
                    <ul>
                      <li v-for="(course, index) in selectedCourseToDisplay" :key="course.id">
                        {{ index + 1 }} . {{ course }}
                      </li>
                    </ul>
                  </div>
                  <div class="action">
                    <label>Select Courses</label>
                    <a-button type="primary" @click="courseDrawer"><PlusOutlined /> Add New Courses</a-button>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="Enrolled" force-render>
                <div class="courslistTab">
                  <a-list item-layout="horizontal" :data-source="assignedCourses">
                    <template #renderItem="{ item, index }">
                      <a-list-item>
                        <a-list-item-meta>
                          <template #avatar>
                            <a-avatar>{{index + 1}}</a-avatar>
                          </template>
                          <template #title>
                            {{ item.course.title }}
                          </template>
                        </a-list-item-meta>
                        <template #actions>
                          <a-tooltip>
                            <template #title>Remove Course</template>
                            <img src="../../../assets/images/deleteicon.svg" @click="showDeleteConfirm(item._id)"/>
                          </a-tooltip>
                        </template>
                      </a-list-item>
                    </template>
                  </a-list>
                </div>
              </a-tab-pane>
              <a-tab-pane key="3" tab="Completed">
                <div class="courslistTab">
                  <a-list item-layout="horizontal" :data-source="completedCourses">
                    <template #renderItem="{ item, index }">
                      <a-list-item>
                        <a-list-item-meta>
                          <template #avatar>
                            <a-avatar>{{index + 1}}</a-avatar>
                          </template>
                          <template #title>
                            {{ item.course.title }}
                          </template>
                        </a-list-item-meta>
                        <template #actions>
                          <!-- <img src="../../../assets/images/deleteicon.svg" @click="unAssignCourse(item._id)"/> -->
                          <a-tooltip>
                            <template #title>Retake Course</template>
                            <span class="reloadicon" @click="confirmRetakeCourse(item, index)">
                              <ReloadOutlined />
                            </span>
                          </a-tooltip>
                        </template>
                      </a-list-item>
                    </template>
                  </a-list>
                </div>
              </a-tab-pane>
              <a-tab-pane key="4" tab="Certificate">
                <div class="courslistTab certificateTab">
                  <a-list item-layout="horizontal" :data-source="certificates">
                    <template #renderItem="{ item, index }">
                      <a-list-item>
                        <a-list-item-meta>
                          <template #avatar>
                            <a-avatar>{{index + 1}}</a-avatar>
                          </template>
                          <template #title>
                            {{ item.course.title }}
                          </template>
                        </a-list-item-meta>
                        <template #actions>
                          <a-tooltip>
                            <template #title>View Certificate</template>
                            <a class="reloadicon viewicon" :href="item.certificate_url" target="_blank"><EyeOutlined /></a>
                          </a-tooltip>
                          <a-tooltip>
                            <template #title>Download Certificate</template>
                            <a class="reloadicon" :href="item.certificate_url" target="_blank" download><DownloadOutlined /></a>
                          </a-tooltip>
                          <a-tooltip>
                            <template #title>Delete Certificate</template>
                            <img src="../../../assets/images/deleteicon.svg" @click="confirmDeleteCertificate(item, index)"/>
                          </a-tooltip>
                        </template>
                      </a-list-item>
                    </template>
                  </a-list>
                </div>
              </a-tab-pane>
            </a-tabs>
            <div class="steps-action">
              <a-button type="danger" shape="circle" size="large" v-if="activeKey != 1" @click="prev">
                <ArrowLeftOutlined />
              </a-button>
              <a-button type="danger" shape="circle" size="large" v-if="activeKey != 4" style="margin-left: 8px" @click="next">
                <ArrowRightOutlined />
              </a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-drawer title="Course List" placement="right" :closable="true" v-model:visible="visible" @ok="closeCourseListModal" class="courselistDrawer" :maskClosable="false" :destroyOnClose="true" :width="500">
        <a-checkbox v-model:checked="selectAllCourse" :indeterminate="indeterminate" @change="onCheckAllChange">
          All Courses
        </a-checkbox>
        <a-checkbox-group v-model:value="selectedCourse" :options="availableCourse"/>
        <a-checkbox-group v-model:value="selectedGroup" :options="availableGroupedCourse"/>
        <div class="footersec">
          <a-button type="primary" @click="assignCourse" :loading="loading">Assign</a-button>
        </div>
      </a-drawer>
    </div>
  </div>
</template>

<script lang="ts">
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { createVNode, defineComponent, ref, reactive, UnwrapRef, toRefs, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { ExclamationCircleOutlined, PlusOutlined, HomeOutlined, ArrowLeftOutlined, ArrowRightOutlined, ReloadOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'

import moment from 'moment'

import validate from '../../../services/validator'
import router from '../../../router'
import profileService from '../../../services/profile'
import courseService from '../../../services/course'
import certificateService from '../../../services/certficate'
import subscriptionService from '../../../services/subscription'
import commonServices from '../../../services/common'

import ICandidate from '../../../interface/profile'
import ICourse from '../../../interface/course'

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    PlusOutlined,
    ReloadOutlined,
    DownloadOutlined,
    EyeOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const activeKey = ref('1')
    const route = useRoute()
    const candidateId = ref<string>(route.params.id as string)
    const candidate = ref<ICandidate>()
    const candidateFormRef = ref()
    const candidateRules = validate.clientValidation
    const unassignedCourses = ref<Array<ICourse>>([])
    const assignedCourses = ref<Array<ICourse>>([])
    const availableCourse = ref([])
    const availableGroupedCourse = ref([])
    const groupCourse = ref([])
    const selectedCourseToDisplay = ref([])
    const visible = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const certificates = ref()
    const completedCourses = ref()
    const availableCredit = ref<number>(0)
    const subscription = ref()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const state = reactive({
      indeterminate: false,
      selectAllCourse: false,
      selectedCourse: [],
      selectedGroup: []
    })
    const candidateForm: UnwrapRef<ICandidate> = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: '',
      profile_type: '',
      certificate_access: false,
      created_by: '',
      is_active: true,
      send_login: false,
      status: 'active'
    })
    const updateCandidate = async () => {
      candidateFormRef.value.validate().then(async () => {
        try {
          await profileService.updateProfile(candidateId.value, candidateForm)
          await notify('Success', 'Profile Updated', 'success')
          router.push(`/client/candidates${loginAs.value}`)
        } catch (error) {
          await notify('Error', error.data, 'error')
        }
      }).catch((error: ValidateErrorEntity<ICandidate>) => {
        console.log('error', error)
      })
    }
    const getCandidateDetails = async () => {
      try {
        const responce = await profileService.getProfileDetails(candidateId.value)
        candidate.value = responce.data
        Object.assign(candidateForm, responce.data)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getCertificates = async () => {
      try {
        const responce = await certificateService.getCertficates(candidateId.value)
        certificates.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getCompletedCourses = async () => {
      try {
        const responce = await courseService.getCompletedCourses(candidateId.value)
        completedCourses.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getUnassignedCourses = async () => {
      try {
        availableCourse.value = []
        const clientId = commonServices.getCurrentProfile()._id
        const responce = await courseService.getCandidateUnAssignedCourse(clientId, candidateId.value)
        unassignedCourses.value = responce.data
        for (const course of unassignedCourses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getAssignedCourse = async () => {
      try {
        const responce = await courseService.getAssignedCourse(candidateId.value)
        assignedCourses.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const closeCourseListModal = async () => {
      visible.value = false
    }
    const unAssignCourse = async (id, index) => {
      try {
        await courseService.unAssignCourse(id)
        assignedCourses.value.splice(index, 1)
        getUnassignedCourses()
        notify('Success', 'Course Unassigned Successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const deleteCertificate = async (data, index) => {
      try {
        await certificateService.deleteCertficate(data._id)
        certificates.value.splice(index, 1)
        notify('Success', 'Certficate revoked', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const retakeCourse = async (data, index) => {
      try {
        await courseService.reTakeCourse(data.course._id, candidateId.value)
        completedCourses.value.splice(index, 1)
        notify('Success', 'Course is now available for retake', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const showDeleteConfirm = async (id, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          unAssignCourse(id, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const confirmDeleteCertificate = async (data, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { }, 'Do you really want to delete this certificate?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          deleteCertificate(data, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const confirmRetakeCourse = async (data, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { }, 'Do you really want to retake this course?. This process cannot be undo. All datas related to this course for the candidate will wipe off.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          retakeCourse(data, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const assignCourse = async () => {
      try {
        const lSubscription = commonServices.getCurrentSubscription()
        const enroll = []
        loading.value = true
        for (const courseId of state.selectedCourse) {
          let expiryDate = ''
          const course = unassignedCourses.value.filter(course => course._id === courseId)
          if (course.length > 0) {
            if (course[0].validity > 0) {
              expiryDate = moment().add(course[0].validity, 'months').format()
            }
          }
          enroll.push({ enrolled_date: new Date(), profile: candidateId.value, course: courseId, expiry_date: expiryDate, subscription: lSubscription._id })
        }
        for (const groupId of state.selectedGroup) {
          const group = groupCourse.value.filter(group => group._id === groupId)
          console.log('group', group)
          if (group.length > 0) {
            for (const course of group[0].courses) {
              let expiryDate = ''
              if (course.validity > 0) {
                expiryDate = moment().add(course.validity, 'months').format()
              }
              enroll.push({ enrolled_date: new Date(), profile: candidate.value._id, course: course._id, expiry_date: expiryDate, subscription: lSubscription._id })
            }
          }
        }
        if (lSubscription.type === 'Credit') {
          const subscribedCourses = await subscriptionService.getSubscribedCourses(lSubscription._id)
          const subscribedCourseCount = subscribedCourses.data.length + enroll.length
          console.log(lSubscription.quantity, subscribedCourseCount)
          if (lSubscription.quantity < subscribedCourseCount) {
            loading.value = false
            notify('Warning', 'You dont have enough credit, please update your credit.', 'warning')
            return
          }
          lSubscription.available_credit = (lSubscription.available_credit - subscribedCourseCount)
          const responce = await subscriptionService.updateSubscription(lSubscription._id, lSubscription)
          localStorage.subscription = JSON.stringify(responce.data)
        }
        await courseService.enrollMultipleCourse(enroll)
        loading.value = false
        closeCourseListModal()
        getUnassignedCourses()
        getAssignedCourse()
        notify('Success', 'Course Assigned Successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const courseDrawer = async () => {
      visible.value = true
    }
    const onCheckAllChange = async (e: any) => {
      const aCourse = []
      const aGroup = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      for (const group of availableGroupedCourse.value) {
        aGroup.push(group.value)
      }
      Object.assign(state, {
        selectedCourse: e.target.checked ? aCourse : [],
        selectedGroup: e.target.checked ? aGroup : [],
        indeterminate: false
      })
    }
    watch(() => state.selectedCourse, (val) => {
      selectedCourseToDisplay.value = []
      for (const course of state.selectedCourse) {
        selectedCourseToDisplay.value.push(availableCourse.value.filter(c => c.value === course)[0].label)
      }
      state.indeterminate = !!val.length && val.length < availableCourse.value.length
      state.selectAllCourse = val.length === availableCourse.value.length
    })
    const next = async () => {
      let number = +activeKey.value
      number++
      activeKey.value = number.toString()
    }
    const prev = async () => {
      let number = +activeKey.value
      number--
      activeKey.value = number.toString()
    }
    const getGroupCourses = async () => {
      try {
        availableGroupedCourse.value = []
        const profile = commonServices.getCurrentProfile()
        const responce = await courseService.getGroupCourse(profile._id)
        groupCourse.value = responce.data
        for (const group of groupCourse.value) {
          availableGroupedCourse.value.push({ label: group.name, value: group._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    // const downloadCertficate = async (course, certficate, candidate, open) => {
    //   try {
    //     await certificateService.downloadCertficate(course, certficate, candidate, open)
    //   } catch (error) {
    //     console.log('error', error)
    //     await notify('Error', error.data, 'error')
    //   }
    // }
    onMounted(() => {
      getCandidateDetails()
      getUnassignedCourses()
      getGroupCourses()
      getAssignedCourse()
      getCertificates()
      getCompletedCourses()
      const lSubscription = commonServices.getCurrentSubscription()
      availableCredit.value = +lSubscription.available_credit
      subscription.value = lSubscription
    })
    return {
      candidateFormRef,
      candidateRules,
      candidateForm,
      updateCandidate,
      activeKey,
      visible,
      courseDrawer,
      closeCourseListModal,
      ...toRefs(state),
      onCheckAllChange,
      next,
      prev,
      availableCourse,
      assignedCourses,
      unassignedCourses,
      selectedCourseToDisplay,
      assignCourse,
      unAssignCourse,
      loading,
      showDeleteConfirm,
      confirmDeleteCertificate,
      // downloadCertficate,
      certificates,
      completedCourses,
      confirmRetakeCourse,
      availableGroupedCourse,
      loginAs
    }
  }
})
</script>

<style lang="scss">
.edituser {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 0px 20px;
    border-radius: 10px;
    .ant-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h4 {
      font-size: 16px;
      text-transform: uppercase;
      color: #646464;
      font-family: "TT Norms Pro Medium";
      margin: 0;
    }
    .add-outline {
      float: right;
      .ant-btn {
        background-color: transparent;
        border-color: #38B6FF;
        color: #38B6FF;
        border-radius: 5px;
        font-family: "TT Norms Pro Medium";
        text-shadow: none;
        box-shadow: none;
        a {
          color: #38B6FF;
        }
      }
    }
  }
}
.edit-candidate {
  .candidateDetails {
    background: #ffffff;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Medium";
      font-size: 16px;
      border-bottom: 1px solid #d9d9d9;
      text-align: left;
      padding: 0 0 10px 0;
    }
    .ant-form {
      margin: 25px 0 0 0;
      .ant-form-item label {
        font-family: "TT Norms Pro Regular";
      }
      .ant-form-explain {
        text-align: left;
      }
      .ant-form-vertical .ant-form-item-control-wrapper {
        text-align: left;
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: #38B6FF;
      }
      .ant-radio-checked::after {
        border: 1px solid #38B6FF;
      }
      .ant-radio-inner::after {
        background-color: #38B6FF;
      }
    }
    .action {
      .ant-btn-primary {
        background-color: #2ecc71;
        border-color: #2ecc71;
        width: 100%;
        border-radius: 4px;
        box-shadow: none;
        font-family: "TT Norms Pro Medium";
        font-size: 16px;
      }
    }
  }
  .tabsection {
    background: #ffffff;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    .ant-tabs-bar {
      border-bottom: 0;
      .ant-tabs-ink-bar-animated {
        display: none !important;
      }
      .ant-tabs-nav .ant-tabs-tab {
        border: 2px solid #ffc788;
        border-radius: 25px;
        padding: 5px 16px;
        width: -webkit-fill-available;
      }
      .ant-tabs-nav .ant-tabs-tab-active {
        background-color: #ffc788;
        color: #fff !important;
      }
      .ant-tabs-nav .ant-tabs-tab:active,
      .ant-tabs-nav .ant-tabs-tab:hover {
        color: #ffc788;
      }
      .ant-tabs-nav-wrap {
        margin-bottom: 0px;
      }
      .ant-tabs-nav {
        width: 100%;
        & > div {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .overviewTab {
      .selectcourselist {
        text-align: left;
        width: 500px;
        margin: 0 auto;
        h4 {
          font-size: 16px;
          text-transform: capitalize;
          color: #646464;
          font-family: "TT Norms Pro Medium";
          margin: 0;
          text-align: left;
          padding: 0 0 5px 0;
          border-bottom: 1px solid #d9d9d9;
        }
        ul {
          list-style: none;
          text-align: left;
          padding-left: 10px;
          li {
            margin: 2px 0;
          }
        }
      }
      .action {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 10% 0 30px 0;
        label {
          margin: 0 0 10px 0;
        }
        button {
          border: 1px solid #2ecc71;
          background: transparent;
          border-radius: 20px;
          box-shadow: none;
          span {
            color: #646464;
            font-family: "TT Norms Pro Regular";
          }
          .anticon {
            color: #2ecc71;
          }
        }
      }
    }
    .courslistTab {
      width: 80%;
      margin: 30px auto;
      .ant-list-item {
        border-bottom: none;
        .ant-avatar {
          font-family: "TT Norms Pro Medium";
          color: #646464;
          background: #d2f8ff;
        }
        .ant-list-item-meta {
          width: 100%;
        }
        .ant-list-item-meta-content {
          background-color: #f7fafc;
          border-radius: 20px;
          height: 36px;
          padding: 0 100px 0 15px;
          width: calc(100% - 260px);
          h4 {
            line-height: 36px;
            text-align: left;
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-transform: uppercase;
            font-size: 12px;
          }
        }
        .ant-list-item-action {
          position: absolute;
          right: 0;
          li {
            img {
              width: 28px;
            }
            .reloadicon {
              background: #ffc788;
              width: 28px;
              height: 28px;
              display: block;
              border-radius: 50%;
              color: #fff;
              text-align: center;
              line-height: 28px;
            }
          }
        }
      }
    }
    .certificateTab{
      width: 100%;
      .ant-list-item {

        .ant-list-item-meta {

        }
        .ant-list-item-meta-content {
              padding: 0 130px 0 15px;
              width: calc(100% - 300px);
    display: flex;
          h4 {
                width: 50%;
          }
          .ant-list-item-meta-description{
              width: 50%;
              white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 36px;
            color: #72cf97;
            text-transform: capitalize;
          }
        }
        .ant-list-item-action {

          li {
            img {

            }
            .viewicon {
              background: #72cf97;
            }
          }
        }
      }
    }
  }
  .steps-action{
    .ant-btn-danger {
          background-color: #ff7875;
          border-color: #ff7875;
        }
  }
}
</style>
